"use client";
import { Contact, postContactContact, ShowRoom } from "@/api";
import { productCarStore } from "@/store";
import theme from "@/theme/theme";

import { useIsDesktop } from "@/common/MediaQueries";
import { getUtmParamsAsKeyValueList } from "@/components/shared/utmParams";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  styled,
  TextField,
  Typography,
} from "@mui/material";
import { sendGTMEvent } from "@next/third-parties/google";
import dynamic from "next/dynamic";
import Image from "next/image";
import { usePathname } from "next/navigation";
import { LegacyRef, useEffect, useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import * as yup from "yup";
import SuccessLottie from "../../Common/Lottie/SuccesLottie.json";

const Lottie = dynamic(() => import("lottie-react"), { ssr: false });

const SuccessOrFailSend = dynamic(() => import("./SuccessOrFailSend"), {
  ssr: false,
});

// eslint-disable-next-line prefer-destructuring
const RECAPTCHA_KEY = process.env.RECAPTCHA_KEY;
if (!RECAPTCHA_KEY) {
  throw new Error("Please provide the RECAPTCHA_KEY environment variable.");
}

const FormWrapper = styled(Box)({
  display: "contents",
  [theme.breakpoints.down("sm")]: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
});

const FormControlWrapper = styled(FormControl)({
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
  [theme.breakpoints.up("md")]: {
    marginBottom: 24,
  },
});

const SendButton = styled(Button)(({}) => ({
  width: 328,
  height: 50,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: 0,
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    marginTop: 32,
  },
}));

const ButtonWrapper = styled(Button)(
  ({
    grow,
    widthsize,
    colorbg,
  }: {
    grow: number;
    colorbg?: string;
    widthsize?: number;
  }) => ({
    width: 328,
    height: 50,
    borderRadius: 0,
    backgroundColor: colorbg ? colorbg : "",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flex: 1,
      flexGrow: grow,
      width: widthsize,
    },
  })
);
interface ContactFormValues {
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  agency: string;
  modelName?: string;
}
// Yup schema for validation

export const FormContact = ({
  agencyOptions,
  contactRef,
}: {
  agencyOptions: ShowRoom[];
  contactRef: (node?: Element | null) => void;
}) => {
  const productCarAgency = productCarStore();
  const [isMailing, setIsMailing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [agencyDefault, setAgencyDefault] = useState("");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const reCaptchaRef = useRef<ReCAPTCHA | null>();
  const isDesktop = useIsDesktop();
  const validationSchema = yup.object().shape({
    firstName: yup
      .string()
      .min(2, "השם הפרטי צריך להיות בין 2-50 תווים")
      .max(50, "שם הפרטי צריך להיות בין 2-50 תווים")
      .required("אנא הזן שם פרטי"),
    lastName: yup
      .string()
      .min(2, "שם המשפחה צריך להיות בין 2-50 תווים")
      .max(50, "שם המשפחה צריך להיות בין 2-50 תווים")
      .required("אנא הזן שם משפחה"),
    phone: yup
      .string()
      .matches(/^0[0-9]{8,9}$/, "יש בעיה במספר הטלפון, כדאי לבדוק ולתקן")
      .required("אנא הזן טלפון"),
    email: yup
      .string()
      .email("יש בעיה במייל, כדאי לבדוק ולתקן")
      .required("יש בעיה במייל, כדאי לבדוק ולתקן"),
    agency: yup.string().required("אנא בחר אולם"),
    modelName: yup.string().max(100, "דגם צריך להיות עד 100 תווים"),
  });

  const pathname = usePathname();
  const onSubmit: SubmitHandler<ContactFormValues> = async (data) => {
    const utmValues = getUtmParamsAsKeyValueList();
    reCaptchaRef?.current?.reset();
    let recaptchaResponse;
    try {
      recaptchaResponse = (await reCaptchaRef?.current
        ?.executeAsync()
        .catch((e) => {
          console.log(e);
        })) as string;
      if (!recaptchaResponse) {
        throw new Error("ReCAPTCHA failed to return a response");
      }
    } catch (error) {
      console.error("ReCAPTCHA failed", error);
      return;
    }
    setIsLoading(true);
    const requestData: Contact = {
      fullName: `${data.firstName} ${data.lastName}`,
      emailAddress: data.email,
      phone: data.phone,
      description: "",
      branchId: Number(
        agencyOptions.find((item) => item.name === data.agency)!.id
      ),
      platform: isDesktop ? "Pc" : "Mobile",
      isMailing,
      utmValues: utmValues,
      sid: "",
      formName: "contact",
      mediaChannelId: "",
      modelId: productCarAgency?.id || 0,
      modelName: data.modelName || productCarAgency?.modelName || "",
      gRecaptchaResponse: recaptchaResponse,
    };
    if (pathname === "/") {
      sendGTMEvent({
        action: "click",
        event: "contact_form_submit_clicks",
      });
    } else {
      sendGTMEvent({
        action: "click",
        event: "contact_form_submit_clicks",
        model_type: data.modelName || productCarAgency?.modelName || "",
        agency_name: data.agency,
      });
    }

    try {
      const sendData = await postContactContact(requestData);
      if (sendData.data.errorCode === 0) {
        setIsLoading(false);
        setSuccess(true);
        if (!isDesktop) {
          const contactElement = document.querySelector("#contact-form");
          if (contactElement) {
            const offset = 100;
            const topPosition =
              contactElement.getBoundingClientRect().top +
              window.scrollY -
              offset;

            setTimeout(() => {
              window.scrollTo({
                top: topPosition,
                behavior: "smooth",
              });
            }, 500);
          }
        }
      } else {
        setError(true);
        setIsLoading(false);
      }
    } catch (error) {
      // Handle error response
      console.error("Error submitting form", error);
      setIsLoading(false);
    }
  };

  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      agency: agencyDefault ? agencyDefault : "",
      modelName: "",
    },
    shouldFocusError: true,

    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    if (productCarAgency?.showRoom?.name) {
      setAgencyDefault(productCarAgency?.showRoom.name);
    }
  }, [productCarAgency]);

  return (
    <FormWrapper
      sx={{
        "& .MuiFormControl-root": {
          width: "100%",
        },
      }}
      component={"form"}
      onSubmit={handleSubmit(onSubmit)}
    >
      <>
        {success ? (
          <SuccessOrFailSend>
            <Lottie animationData={SuccessLottie} loop={true} />
            <Typography
              variant="h5"
              fontSize={18}
              fontWeight={300}
              mb={1}
              color="#000"
            >
              פרטיך נקלטו בהצלחה!
            </Typography>
            <Typography
              variant="body1"
              fontSize={14}
              fontWeight={400}
              color="#000"
            >
              נציגנו יצור עימך קשר בהקדם
            </Typography>
          </SuccessOrFailSend>
        ) : error ? (
          <SuccessOrFailSend>
            <Image
              style={{ marginBottom: 38 }}
              src="/errorIcon.svg"
              alt="errorIcon"
              width={93}
              height={93}
            />
            <Typography
              variant="h5"
              fontSize={18}
              fontWeight={300}
              mb={1}
              color="#000"
            >
              משהו השתבש!
            </Typography>
            <Typography
              variant="body1"
              fontSize={14}
              fontWeight={400}
              color="#000"
              mb={6}
            >
              נראה שמשהו השתבש והטופס לא נשלח. אנא נסה שנית
            </Typography>
            <ButtonWrapper
              onClick={() => setError(false)}
              widthsize={194}
              grow={2}
              variant="contained"
              color="primary"
            >
              נסה שנית
            </ButtonWrapper>
          </SuccessOrFailSend>
        ) : (
          <>
            <Grid
              container
              // style={{ width: "100% !important" }}
              rowSpacing={5}
              columnSpacing={3}
              paddingInline={{ xs: 0, md: 4 }}
            >
              <Grid position={"relative"} item xs={12} paddingBottom={1}>
                <Typography
                  alignSelf={"flex-start"}
                  fontSize={12}
                  fontWeight={400}
                >
                  *שדות המסומנים בכוכב הם חובה
                </Typography>
              </Grid>
              <Grid position={"relative"} item xs={12} md={6}>
                <Controller
                  name="firstName"
                  control={control}
                  render={({ field }) => {
                    return (
                      <TextField
                        {...field}
                        label="*איך קוראים לך?"
                        variant="outlined"
                        error={errors.firstName ? true : false}
                        sx={{
                          "& .MuiInputLabel-root": {
                            inset: "0 32px 0 auto",
                            transformOrigin: "right",
                          },
                          "& .MuiFormHelperText-root": {
                            top: "calc(-100% + 87px)!important",
                          },
                        }}
                        helperText={
                          errors.firstName?.message
                            ? errors.firstName.message
                            : ""
                        }
                      />
                    );
                  }}
                />
              </Grid>
              <Grid position={"relative"} item xs={12} md={6}>
                <Controller
                  name="lastName"
                  control={control}
                  render={({ field }) => {
                    return (
                      <TextField
                        {...field}
                        label="*גם שם משפחה בבקשה"
                        variant="outlined"
                        error={errors.lastName ? true : false}
                        sx={{
                          "& .MuiInputLabel-root": {
                            inset: "0 32px 0 auto",
                            transformOrigin: "right",
                          },
                          "& .MuiFormHelperText-root": {
                            top: "calc(-100% + 87px)!important",
                          },
                        }}
                        helperText={
                          errors.lastName?.message
                            ? errors.lastName.message
                            : ""
                        }
                      />
                    );
                  }}
                />
              </Grid>
              <Grid position={"relative"} item xs={12} md={6}>
                <Controller
                  name="phone"
                  control={control}
                  render={({ field }) => {
                    return (
                      <TextField
                        {...field}
                        label="*נייד"
                        variant="outlined"
                        error={errors.phone ? true : false}
                        sx={{
                          "& .MuiInputLabel-root": {
                            inset: "0 32px 0 auto",
                            transformOrigin: "right",
                          },
                          "& .MuiFormHelperText-root": {
                            top: "calc(-100% + 87px)!important",
                          },
                        }}
                        helperText={
                          errors.phone?.message ? errors.phone.message : ""
                        }
                      />
                    );
                  }}
                />
              </Grid>
              <Grid position={"relative"} item xs={12} md={6}>
                <Controller
                  name="email"
                  control={control}
                  render={({ field }) => {
                    return (
                      <TextField
                        {...field}
                        label="*מייל"
                        variant="outlined"
                        error={errors.email ? true : false}
                        sx={{
                          "& .MuiInputLabel-root": {
                            inset: "0 32px 0 auto",
                            transformOrigin: "right",
                          },
                          "& .MuiFormHelperText-root": {
                            top: "calc(-100% + 87px)!important",
                          },
                        }}
                        helperText={
                          errors.email?.message ? errors.email.message : ""
                        }
                      />
                    );
                  }}
                />
              </Grid>

              <Grid position={"relative"} item xs={12} md={6}>
                <Controller
                  name="agency"
                  control={control}
                  render={({ field }) => {
                    useEffect(() => {
                      if (agencyDefault) {
                        field.onChange(agencyDefault); // Manually set the field value when agencyDefault changes
                      }
                    }, [agencyDefault]);
                    return (
                      <FormControlWrapper
                        fullWidth
                        style={{ position: "relative" }}
                      >
                        <InputLabel
                          required
                          id="agency-label"
                          error={errors.agency ? true : false}
                          sx={{
                            left: "auto",
                            right: "14px",
                            transform: "translate(0, 9px) scale(1)",
                          }}
                        >
                          *מאיזה אולם לחזור אליך?
                        </InputLabel>
                        <Select
                          {...field}
                          disabled={agencyDefault ? true : false}
                          value={field.value || agencyDefault}
                          labelId="agency-label"
                          id="agency"
                          error={errors.agency ? true : false}
                          sx={{
                            textAlign: "right",
                            ".MuiSelect-icon": {
                              inset: "auto auto auto 8px",
                            },
                            ".MuiSelect-select": {
                              paddingInlineStart: "8px!important",
                            },
                          }}
                        >
                          {agencyOptions.map((agency) => (
                            <MenuItem key={agency.id} value={agency.name}>
                              {agency.name}
                            </MenuItem>
                          ))}
                        </Select>
                        {errors.agency && (
                          <FormHelperText
                            sx={{
                              top: "calc(-100% + 87px)!important",
                            }}
                          >
                            {errors.agency.message}
                          </FormHelperText>
                        )}
                      </FormControlWrapper>
                    );
                  }}
                />
              </Grid>
              <Grid position={"relative"} item xs={12} md={6}>
                <Controller
                  disabled={productCarAgency?.modelName ? true : false}
                  name="modelName"
                  control={control}
                  render={({ field }) => {
                    return (
                      <TextField
                        {...field}
                        inputProps={{ maxLength: 100 }}
                        value={productCarAgency?.modelName ?? field.value}
                        error={errors.modelName ? true : false}
                        sx={{
                          "& .MuiInputLabel-root": {
                            inset: "0 32px 0 auto",
                            transformOrigin: "right",
                          },
                        }}
                        helperText={
                          errors.modelName?.message
                            ? errors.modelName.message
                            : ""
                        }
                        label="על איזה דגם חשבת?"
                        variant="outlined"
                      />
                    );
                  }}
                />
              </Grid>
              <Grid item xs={12} md={12} style={{ paddingTop: 12 }}>
                <FormGroup row>
                  <FormControlLabel
                    sx={{ marginInlineStart: "-9px", alignItems: "flex-start" }}
                    control={
                      <Checkbox
                        sx={{
                          paddingInlineEnd: "4px",
                          paddingTop: "0px",
                          marginTop: "2px",
                        }}
                        checked={isMailing}
                        onChange={() => setIsMailing(!isMailing)}
                      />
                    }
                    label={
                      <Typography fontSize={12} fontWeight={400}>
                        אני רוצה לקבל פניות ועדכונים במייל ו/או בווטסאפ ו/או
                        בסמס מקבוצת דלק מוטורס על מבצעים, השקות, אירועים ועוד,
                        ולהיכלל במאגר המידע של הקבוצה{" "}
                      </Typography>
                    }
                  />
                </FormGroup>
              </Grid>
            </Grid>
            <SendButton
              type="submit"
              variant="contained"
              color="primary"
              disabled={isLoading}
            >
              שליחה
            </SendButton>
            {RECAPTCHA_KEY && (
              <ReCAPTCHA
                ref={reCaptchaRef as LegacyRef<ReCAPTCHA> | undefined}
                sitekey={RECAPTCHA_KEY}
                size="invisible"
                hl="iw"
                tabIndex={-1}
              />
            )}
          </>
        )}
      </>
    </FormWrapper>
  );
};
